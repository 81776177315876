export default [
  {
    id: "2f0a92a4-38f1-446a-8be6-8f97425728d4",
    resource: {
      name: "carno",
      type: "SITE",
    },
  },
  {
    id: "f4ad1ce7-0607-4666-8474-30201c547d6d",
    resource: {
      name: "ne8o1",
      type: "SITE",
    },
  },
  {
    id: "2793b311-9a66-4b87-8d74-31726788e4af",
    resource: {
      name: "q0fnyu",
      type: "SITE",
    },
  },
  {
    id: "6a1c2d17-11c5-4b56-8d89-b90327ecfe93",
    resource: {
      name: "r8g0el",
      type: "SITE",
    },
  },
  {
    id: "8598d6ef-50ac-4c79-afd0-53d9a5e1aead",
    resource: {
      name: "i08tvw",
      type: "SITE",
    },
  },
  {
    id: "79e07840-342b-4813-991e-5439f5ff9821",
    resource: {
      name: "kn977r",
      type: "SITE",
    },
  },
  {
    id: "46675941-10d1-454a-89ec-39d4a8e995d2",
    resource: {
      name: "e24htj",
      type: "SITE",
    },
  },
  {
    id: "fc68574c-aefb-42ea-a8f2-dea479387f87",
    resource: {
      name: "9pspin",
      type: "SITE",
    },
  },
  {
    id: "2bfce444-2fbc-4f56-9ec3-25260598b605",
    resource: {
      name: "4h576n",
      type: "SITE",
    },
  },
  {
    id: "1309e328-9858-4dfe-84c4-2ca1cd7c8f1c",
    resource: {
      name: "hic59f1599124206814",
      type: "SITE",
    },
  },
  {
    id: "eb042376-dbd0-4fcf-b6fe-43271cab61ab",
    resource: {
      name: "gehfsc1599125385871",
      type: "SITE",
    },
  },
  {
    id: "ae3a222d-aca2-45e0-bb60-8b9a643f6b7b",
    resource: {
      name: "xve7c1599125489179",
      type: "SITE",
    },
  },
  {
    id: "629848d6-94ba-42b2-81d7-b7e2edf9727a",
    resource: {
      name: "uvc0k1599125511401",
      type: "SITE",
    },
  },
  {
    id: "ac1b27e8-90aa-45d7-91ae-19c695d3cbc2",
    resource: {
      name: "ei7qd5e1599125552979",
      type: "SITE",
    },
  },
  {
    id: "77fd9f3d-075a-49e0-81eb-039e2660a18c",
    resource: {
      name: "djnnvv1599125652885",
      type: "SITE",
    },
  },
  {
    id: "8f6860dc-3d23-465a-89f3-f8df4168cff0",
    resource: {
      name: "lmm3rc1599125716390",
      type: "SITE",
    },
  },
];
