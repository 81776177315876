import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { forecastSlice } from "./forecastSlice";
import { assetSlice, getAssetState } from "./assetSlice";
import { rootSagas } from "./sagas/sagas";
import { IWidgetApiClient } from "../ApiClient";

export const reducer = combineReducers({
  assets: assetSlice.reducer,
  forecasts: forecastSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;

export const setupStore = (apiClient: IWidgetApiClient) => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      apiClient,
    },
  });

  const store = configureStore({
    preloadedState: {
      assets: getAssetState([], undefined),
    },
    reducer,
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
  });

  sagaMiddleware.run(rootSagas);
   
  store.dispatch(assetSlice.actions.getAssetList());
  return store;
};
