import { AxiosRequestConfig, AxiosInstance } from "axios";
import moment from "moment";
import { ApiClient, IApiClient } from "@OrigamiEnergyLtd/renewables-plus-api-client";

import { Asset } from "./redux/assetSlice";
import {
  Forecast,
  ForecastPredictions,
  PublishedForecast,
  PublishedPrediction,
} from "./redux/forecastSlice";

export type RetrieveForecastListResult = {
  forecasts: Forecast[],
  isLastPage: boolean
}

export interface IWidgetApiClient extends IApiClient {
  retrieveForecastList(
    assetId: string,
    targetDeliveryTimeAfter: Date,
    targetDeliveryTimeBefore: Date,
    page: number,
    sortAsc?: boolean
  ): Promise<RetrieveForecastListResult>;
  retrieveForecast(
    assetId: string,
    forecastId: string,
    start: string,
    end: string
  ): Promise<ForecastPredictions>;
  retrievePublishedForecastList(
    assetId: string,
    targetDeliveryRangeStart: Date,
    targetDeliveryRangeEnd: Date,
    page: number,
    sortAsc?: boolean
  ): Promise<PublishedForecast[]>;
  publishForecast(assetId: string, forecastId: string): Promise<void>;
  retrieveAssets(): Promise<Asset[]>;
  retrievePublishedPosition(
    assetId: string,
    start: string,
    end: string
  ): Promise<PublishedPrediction[]>;
}

export class WidgetApiClient implements IWidgetApiClient {
  private axiosInstance: AxiosInstance;
  private constructor(private apiClient: ApiClient) { 
    this.axiosInstance = apiClient.axiosInstance;
  }
 
  public static async build(baseApi: string) {
    const apiClient = await ApiClient.build(baseApi);
    return new WidgetApiClient(apiClient)
  }

  public cleanup() {
    this.apiClient.cleanup();
  }

  public async retrieveForecastList(
    assetId: string,
    targetDeliveryTimeAfter: Date,
    targetDeliveryTimeBefore: Date,
    page: number = 1,
    sortAsc = false
    // names?: string,
    // latestAsOf?: Date
  ) {
    const url = `/v1/assets/${assetId}/forecasts`;
    const params = {
      sort: `predictionMoment,${sortAsc ? "asc" : "desc"}`,
      targetDeliveryTimeAfter,
      targetDeliveryTimeBefore,
      page,
    };

    const config: AxiosRequestConfig = { params };

    const response = await this.axiosInstance.get(url, config);
    const published = await this.retrievePublishedForecastList(
      assetId,
      targetDeliveryTimeAfter,
      targetDeliveryTimeBefore,
      page,
      sortAsc
    );

    const forecasts = response.data.content.map((d: any) => {
      const publishedForecast = published.find((p) => p.forecastId === d.id);
      return {
        id: d.id,
        name: d.resource.name,
        predictionMoment: moment(d.resource.predictionMoment).valueOf(),
        startTargetPeriod: moment(d.resource.startTargetPeriod).valueOf(),
        endTargetPeriod: moment(d.resource.endTargetPeriod).valueOf(),
        settlementPeriodLength: Math.round(d.resource.targetPeriodLengths / 60),
        published: publishedForecast?.publishedDate,
        isLatestPublished: publishedForecast?.isLatest,
      } as Forecast;
    })

    return {forecasts, isLastPage: response.data.last};
  }

  public async retrieveForecast(
    assetId: string,
    forecastId: string,
    start: string,
    end: string
  ): Promise<ForecastPredictions> {
    const url = `/v1/assets/${assetId}/forecasts/${forecastId}/predictions`;

    const params = {
      targetDeliveryStart: start,
      targetDeliveryEnd: end,
    };
    const config: AxiosRequestConfig = { params };

    const response = await this.axiosInstance.get(url, config);

    const result: ForecastPredictions = {
      id: forecastId,
      predictions: response.data.content.map((predictionRaw: any) => ({
        start: moment(predictionRaw.resource.targetDeliveryStart).valueOf(),
        end: moment(predictionRaw.resource.targetDeliveryEnd).valueOf(),
        power: predictionRaw.resource.power,
        name: predictionRaw.resource.name,
      })),
    };
    return result;
  }

  public async retrievePublishedForecastList(
    assetId: string,
    targetDeliveryRangeStart: Date,
    targetDeliveryRangeEnd: Date,
    page: number = 1,
    sortAsc = false
  ): Promise<PublishedForecast[]> {
    const url = `/v1/assets/${assetId}/published-forecasts`;
    const params = {
      sort: `createdAt,${sortAsc ? "asc" : "desc"}`,
      targetDeliveryRangeStart,
      targetDeliveryRangeEnd,
      page,
    };

    const response = await this.axiosInstance.get(url, { params });
    return (
      response.data.content?.map((d: any) => ({
        forecastId: d.resource.forecastId,
        publishedDate: moment(d._metadata.publishedDate).valueOf(),
        isLatest: d._metadata.latest,
      })) ?? []
    );
  }

  public async publishForecast(
    assetId: string,
    forecastId: string
  ): Promise<void> {
    const url = `/v1/assets/${assetId}/published-forecasts`;
    const body = { forecastId };
    await this.axiosInstance.post(url, body);
  }

  public async retrieveAssets() {
    const url = "/v1/assets";
    const config: AxiosRequestConfig = {
      params: {
        page: 0,
      },
    };
    const response = await this.axiosInstance.get(url, config);
    const assets: Asset[] = response.data.content.map((rawAsset: any) => {
      return {
        id: rawAsset.id,
        label: rawAsset.resource.name,
      };
    });
    return assets;
  }

  public async retrievePublishedPosition(
    assetId: string,
    targetDeliveryRangeStart: string,
    targetDeliveryRangeEnd: string
  ) {
    const url = `/v1/assets/${assetId}/forecast-position`;
    const params = {
      targetDeliveryRangeStart,
      targetDeliveryRangeEnd,
    };
    const response = await this.axiosInstance.get(url, { params });
    return response.data.map((predictionRaw: any) => ({
      start: moment(predictionRaw.targetDeliveryStart).valueOf(),
      end: moment(predictionRaw.targetDeliveryEnd).valueOf(),
      power: predictionRaw.forecast.prediction.power,
      name: predictionRaw.forecast.prediction.name,
      publishedForecast: {
        forecastId: predictionRaw.publishedForecast.id,
        publishedDate: moment(
          predictionRaw.publishedForecast.published
        ).valueOf(),
        isLatest: !!predictionRaw.publishedForecast.isLatest,
      },
    }));
  }

}
