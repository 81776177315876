import * as d3 from "d3";
import moment from "moment";

export type TimeRange = { from: Date; to: Date };

export type DataTime = {
  date: Date;
  value: number;
};

export type SelectedEvent = {
  key: string;
  bar: { start: number; end: number };
  dataFrom?: DataTime;
  dataTo?: DataTime;
};

type DataTimeInternal = DataTime & { key: string };

export class D3BarChart {
  private d3Container: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private boundingRect: { width: number; height: number };
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number>;
  private xTimeScale: d3.ScaleTime<number, number>;
  private hoverBars: d3.Selection<SVGRectElement, string, SVGGElement, unknown>;
  private currentTimeLineGroup: d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
  >;
  private shadowsGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
  private nowLabelGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
  private indexedDataTo: { [key: string]: DataTimeInternal };
  private indexedDataFrom: { [key: string]: DataTimeInternal };

  constructor(
    private timeRange: TimeRange,
    private minutesInterval: number,
    container: HTMLDivElement,
    dataFrom: DataTime[],
    dataTo: DataTime[],
    axis: boolean,
    maxValue?: number,
    private onMouseOver?: (event?: SelectedEvent) => void
  ) {
    const marginLeft = axis ? 80 : 0;
    const marginRight = axis ? 15 : 0;
    const marginBottom = axis ? 40 : 0;

    this.d3Container = d3.select(container);
    this.cleanup();
    this.svg = this.createSvg();

    const keys = this.generateKeys(timeRange, minutesInterval);
    const allValues = [...dataFrom, ...dataTo].map((it) => it.value);

    this.boundingRect = container.getBoundingClientRect();

    this.xScale = d3
      .scaleBand()
      .domain(keys)
      .range([marginLeft, this.boundingRect.width - marginRight]);

    this.xTimeScale = d3
      .scaleTime()
      .domain([timeRange.from, timeRange.to])
      .range([
        this.xScale(keys[0])!,
        this.xScale(keys[keys.length - 1])! + this.xScale.bandwidth(),
      ]);

    this.yScale = d3
      .scaleLinear()
      .domain([
        Math.min(...allValues, 0),
        // HACK: Max needs to have a small multiplier else the line gets cropped at the max value
        (maxValue ? maxValue : Math.max(...allValues, 0))*1.01, 
      ])
      .range([this.boundingRect.height - marginBottom, 0]);

    const internalDataTo = this.toDataTimeInternalArray(dataTo, keys);
    const internalDataFrom = this.toDataTimeInternalArray(dataFrom, keys);

    this.indexedDataTo = this.indexData(internalDataTo);
    this.indexedDataFrom = this.indexData(internalDataFrom);

    const upperData = internalDataFrom.filter((fromItem) =>
      this.indexedDataTo[fromItem.key]
        ? this.indexedDataTo[fromItem.key].value < fromItem.value
        : false
    );
    const lowerData = internalDataFrom.map((fromItem) => ({
      ...fromItem,
      value: this.indexedDataTo[fromItem.key]
        ? Math.min(this.indexedDataTo[fromItem.key].value, fromItem.value)
        : fromItem.value,
    }));

    this.drawBars("fromUpper", upperData, "#BC0200");
    this.drawBars("actual", internalDataTo, "#00786C");
    this.drawBars("fromLower", lowerData, "#0D4C49");
    this.drawStepLine(internalDataTo);
    this.shadowsGroup = this.createGroup("shadows");
    this.currentTimeLineGroup = this.createGroup("currentTimeLine");
    this.nowLabelGroup = this.createGroup("nowLabel");
    this.hoverBars = this.drawHoverBars(keys);

    // Draw axis last so bars don't sit on top and hide it
    if (axis) {
      this.drawAxis(marginLeft, this.maxHeight);
    }
  }

  public set currentTime(current: Date) {
    this.drawCurrentTimeLine(current);
    this.drawShadows(current);
    this.drawNowLabel(current);
  }

  public setSelected(key?: string) {
    this.hoverBars
      .filter((item) => item !== key)
      .style("fill", "rgb(255,255,255, 0)");
    if (key)
      this.hoverBars
        .filter((item) => item === key)
        .style("fill", "rgb(255,255,255, 0.1)");
  }
  private indexData(data: DataTimeInternal[]) {
    return data.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {});
  }

  private drawAxis(leftPos: number, topPos: number) {
    const bottomAxis = d3
      .axisBottom(this.xTimeScale)
      .ticks(d3.timeHour.every(this.boundingRect.width > 800 ? 1 : 2))
      .tickFormat((key) => moment(key.valueOf()).format("HH:mm"));

    this.svg
      .append("g")
      .attr("transform", `translate(0,${topPos})`)
      .attr("data-testid", "x-axis")
      .call(bottomAxis);

    const leftAxis = d3
      .axisLeft(this.yScale)
      .ticks(topPos / 50)
      .tickFormat((value) => `${value.valueOf() / 1_000_000} MW`);

    this.svg
      .append("g")
      .attr("transform", `translate(${leftPos},0)`)
      .attr("data-testid", "y-axis")
      .call(leftAxis);
  }

  private generateKeys(timeRange: TimeRange, minutesInterval: number) {
    const result: string[] = [];
    const last = moment(timeRange.to);
    let current = moment(timeRange.from);
    while (current.isBefore(last)) {
      result.push(this.formatKey(current));
      current.add(minutesInterval, "minute");
    }
    return result;
  }

  private formatKey(dateMoment: moment.Moment) {
    return dateMoment.format("YYYY-MM-DD HH:mm");
  }

  private toDataTimeInternalArray(data: DataTime[], keys: string[]) {
    return data
      .map((it) => this.toDataTimeInternal(it))
      .filter((it) => keys.indexOf(it.key) !== -1)
      .sort((a, b) => {
        const aTime = a.date.getTime();
        const bTime = b.date.getTime();
        if (aTime < bTime) return -1;
        if (aTime > bTime) return 1;
        return 0;
      });
  }

  private toDataTimeInternal(data: DataTime): DataTimeInternal {
    return { ...data, key: this.formatKey(moment(data.date)) };
  }

  private createGroup(className: string) {
    return this.svg.append<SVGGElement>("g").classed(className, true);
  }

  private roundedTimeInterval(date: Date) {
    const start = moment(date);
    const remainder = start.minute() % this.minutesInterval;
    return moment(start).subtract(remainder, "minutes");
  }

  private createSvg() {
    return this.d3Container
      .append("svg")
      .style("width", "100%")
      .style("height", "100%");
  }

  private cleanup() {
    this.d3Container.select("svg").remove();
  }

  private isInTimeScale(date: Date): boolean {
    const timestamp = date.getTime();
    const [min, max] = this.xTimeScale.domain().map((d) => d.getTime());
    return min < timestamp && timestamp < max;
  }

  private drawNowLabel(current: Date) {
    const selection = this.nowLabelGroup
      .selectAll<SVGTextElement, Date[]>("text")
      .data([current]);

    selection
      .enter()
      .append("text")
      .text("Now")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "central")
      .attr("y", this.maxHeight + 25)
      .merge(selection)
      .style("fill", (item) =>
        this.isInTimeScale(item) ? "#FFFFFF" : "rgb(0,0,0,0)"
      )
      .style("font-size", "10")
      .attr("x", (item) => this.xTimeScale(item)!);
  }

  private drawStepLine(data: DataTimeInternal[]) {
    const dataLine: [number, number][] = data
      .filter((item) => this.xScale(item.key))
      .map((item) => [this.xScale(item.key)!, this.yScale(item.value)!]);
    
      if (dataLine.length === 0) return;

    dataLine.push([
      dataLine[dataLine.length - 1][0] + this.xScale.bandwidth(),
      dataLine[dataLine.length - 1][1],
    ]);
    const d3StepLine = d3.line().curve(d3.curveStepAfter)(dataLine)!;

    this.svg
      .append("g")
      .classed("stepLine", true)
      .append("path")
      .style("fill", "none")
      .style("stroke", "#FFFFFF")
      .style("stroke-width", this.boundingRect.width < 300 ? "2px" : "2px")
      .attr("d", d3StepLine);
  }

  private drawCurrentTimeLine(current: Date) {
    const selection = this.currentTimeLineGroup
      .selectAll<SVGLineElement, Date[]>("line")
      .data([current]);

    selection
      .enter()
      .append("line")
      .style("stroke", "#FFFFFF")
      .style("stroke-width", "1")
      .style("stroke-dasharray", "2 2")
      .attr("y1", 0)
      .attr("y2", this.maxHeight)
      .merge(selection)
      .style("stroke", (item) =>
        this.isInTimeScale(item) ? "#FFFFFF" : "rgb(0,0,0,0)"
      )
      .attr("x1", (item) => this.xTimeScale(item)!)
      .attr("x2", (item) => this.xTimeScale(item)!)
      .exit()
      .remove();
  }

  private shadowData(date: Date) {
    const nextHour = this.roundedTimeInterval(
      moment(date).add(1.5, "hour").toDate()
    ).toDate();
    return [
      [this.timeRange.from, date],
      [date, nextHour],
    ];
  }

  private drawShadows(current: Date) {
    if (current.getTime() < this.xTimeScale.domain()[0].getTime()) return;
    
    const shadowColor = (startDate: Date) => {
      if (startDate.getTime() < current.getTime()) {
        return "rgba(25, 31, 37, 0.5)";
      }

      return "rgb(128,128,128, 0.1)";
    };

    const selection = this.shadowsGroup
      .selectAll<SVGRectElement, Date[]>("rect")
      .data(this.shadowData(current));

    selection
      .enter()
      .append("rect")
      .attr("height", this.maxHeight)
      .attr("y", 0)
      .merge(selection)
      .style("fill", (item) => shadowColor(item[0])!)
      .attr("width", (item) =>
        Math.max(this.xTimeScale(item[1])! - this.xTimeScale(item[0])!, 0)
      )
      .attr("x", (item) => this.xTimeScale(item[0])!)
      .exit()
      .remove();
  }

  private drawBars(groupName: string, data: DataTimeInternal[], color: string) {
    this.createGroup(groupName)
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", color)
      .style("stroke", "rgb(0,0,0, 0.2)")
      .style("stroke-width", "1")
      .attr("width", this.xScale.bandwidth())
      .attr("height", (item) => this.maxHeight - this.yScale(item.value)!)
      .attr("value", (item) => item.value)
      .attr("x", (item) => this.xScale(item.key)!)
      .attr("y", (item) => this.yScale(item.value)!);
  }

  private drawHoverBars(keys: string[]) {
    return this.svg
      .append("g")
      .classed("hover-bar", true)
      .selectAll("rect")
      .data(keys)
      .enter()
      .append("rect")
      .style("fill", "rgb(255,255,255, 0)")
      .attr("width", this.xScale.bandwidth())
      .attr("height", this.maxHeight)
      .attr("x", (key) => this.xScale(key)!)
      .attr("y", 0)
      .on("mouseover", (key) => {
        this.onMouseOver?.({
          key,
          bar: {
            start: this.xScale(key)!,
            end: this.xScale(key)! + this.xScale.bandwidth(),
          },
          dataFrom: this.indexedDataFrom[key],
          dataTo: this.indexedDataTo[key],
        });
      });
  }

  private get maxHeight() {
    return Math.max(...this.yScale.range());
  }
}
