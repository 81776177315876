import { createSelector } from "reselect";

import { ForecastState, Prediction } from "./forecastSlice";
import { RootState } from "./store";
import { assetAdapter, AssetState } from "./assetSlice";

const forecastStateSelector = (state: RootState): ForecastState =>
  state.forecasts;

const assetStateSelector = (state: RootState): AssetState => state.assets;

const dataChart = (predictions: Prediction[]) => {
  return predictions.map((p) => ({
    date: new Date(p.start),
    value: p.power,
  }));
};

export const isLastPageSelector = createSelector(
    forecastStateSelector,
    state => state.isLastPage
)

export const currentPageSelector = createSelector(
    forecastStateSelector,
    state => state.page
)

export const forecastListSelector = createSelector(
  forecastStateSelector,
  (state) => state.forecasts
);

export const timeRangeSelector = createSelector(
  forecastStateSelector,
  (forecastState) =>
    forecastState.timeRange
      ? {
          from: new Date(forecastState.timeRange.from),
          to: new Date(forecastState.timeRange.to),
        }
      : undefined
);

export const minutesIntervalSelector = createSelector(
  forecastStateSelector,
  (forecastsState) => forecastsState.minutesInterval || 0
);

export const publishedPredictionsSelector = (state: RootState) =>
  state.forecasts.publishedPredictions;

export const selectedForecastSelector = (state: RootState) =>
  state.forecasts.selectedForecast;

export const selectedForecastMomentSelector = createSelector(
  selectedForecastSelector,
  forecastListSelector,
  (selectedForecast, forecastList) =>
    forecastList.find((forecast) => forecast.id === selectedForecast?.id)
      ?.predictionMoment
);

export const publishedPredictionsDataChartSelector = createSelector(
  publishedPredictionsSelector,
  (publishedPredictions) => {
    if (publishedPredictions) return dataChart(publishedPredictions);
    return [];
  }
);

export const selectedForecastDataChartSelector = createSelector(
  selectedForecastSelector,
  (forecast) => {
    if (forecast) return dataChart(forecast.predictions);
    return [];
  }
);

export const forecastDataChartSelector = createSelector(
  timeRangeSelector,
  minutesIntervalSelector,
  publishedPredictionsDataChartSelector,
  selectedForecastDataChartSelector,
  (timeRange, minutesInterval, publishedDataChart, selectedDataChart) => ({
    timeRange,
    minutesInterval,
    publishedDataChart,
    selectedDataChart,
  })
);

export const assetListSelector = assetAdapter.getSelectors(assetStateSelector)
  .selectAll;

export const currentAssetSelector = createSelector(
  assetStateSelector,
  (assetState) =>
    assetState.selectedAsset
      ? assetState.entities[assetState.selectedAsset]
      : undefined
);

export const loadingAssetSelector = createSelector(
  assetStateSelector,
  (assetState) => assetState.loadingAssets
);
