import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {assetSlice} from "./assetSlice";

export type TimeStampRange = { from: number; to: number };

export type PublishedPrediction = Prediction & {
  publishedForecast: PublishedForecast;
};

export type SetForecastPublishedPayload = PublishedForecast & {
  publishedPredictions: PublishedPrediction[];
};

export type Prediction = {
  start: number;
  end: number;
  power: number;
  name?: string;
};

export type Forecast = {
  id: string;
  name: string;
  predictionMoment: number;
  startTargetPeriod: number;
  endTargetPeriod: number;
  settlementPeriodLength: number;
  published?: number; // The time at which the forecast was published (if it was)
  isLatestPublished?: boolean;
};

export type ForecastPredictions = {
  id: string;
  predictions: Prediction[];
};

export type PublishedForecast = {
  forecastId: string;
  publishedDate: number;
  isLatest: boolean;
};

export type UpdateForecastPayload = {
  timeRange: TimeStampRange;
  forecasts: Forecast[];
  latestForecast: ForecastPredictions | undefined;
  publishedPredictions: PublishedPrediction[];
  newPage: number;
  isLastPage: boolean;
  selectedForecast?: ForecastPredictions
};

export type SelectForecastPayload = {
  assetId: string;
  forecastId: string;
};

export type PublishForecastPayload = SelectForecastPayload;

export type ForecastState = {
  page?: number;
  timeRange?: TimeStampRange;
  forecasts: Forecast[];
  publishedPredictions?: PublishedPrediction[];
  selectedForecast?: ForecastPredictions;
  minutesInterval?: number;
  isLastPage?: boolean;
};

const initialState: ForecastState = {
  forecasts: [],
};

export const forecastSlice = createSlice({
  name: "forecast",
  initialState: initialState,
  reducers: {
    updateForecasts: (
      state,
      {
        payload: { forecasts, timeRange, publishedPredictions, latestForecast, newPage, isLastPage, selectedForecast },
      }: PayloadAction<UpdateForecastPayload>
    ) => {

      /* Filter duplicate forecasts */
      const forecastIds = state.forecasts.map(forecast => forecast.id);

      forecasts.forEach(forecast => {
        if (!forecastIds.includes(forecast.id)) {
          forecastIds.push(forecast.id);
          state.forecasts.push(forecast)
        }
      })

      state.timeRange = timeRange;

      if (selectedForecast) {
        state.selectedForecast = selectedForecast
      }
      else {
        if(!state.selectedForecast) state.selectedForecast = latestForecast;
      }
      
      state.publishedPredictions = publishedPredictions;

      const interval = state.forecasts.find((f) => f.id === latestForecast?.id)
        ?.settlementPeriodLength;
      state.minutesInterval = interval;
      state.page = newPage
      state.isLastPage = isLastPage;
    },
    setSelectedForecast: (
      state,
      action: PayloadAction<ForecastPredictions>
    ) => {
      state.selectedForecast = action.payload;
    },
    loadingSelectedForecast: (state) => {
      delete state.selectedForecast;
    },
    setForecastPublished: (
      state,
      {
        payload: { forecastId, publishedPredictions, publishedDate, isLatest },
      }: PayloadAction<SetForecastPublishedPayload>
    ) => {
      const publishedForecast = state.forecasts.find(
        (f) => f.id === forecastId
      );
      if (publishedForecast) {
        state.forecasts.forEach((f) => {
          f.isLatestPublished = false;
        });
        publishedForecast.published = publishedDate;
        publishedForecast.isLatestPublished = isLatest;
      }

      state.publishedPredictions = publishedPredictions;
    },
    // Trigger actions for sagas
    selectForecast: (state, action: PayloadAction<SelectForecastPayload>) => {},
    getForecastList: (state, action: PayloadAction<string>) => {
      delete state.publishedPredictions;
      delete state.selectedForecast;
    },
    publishForecast: (
      state,
      action: PayloadAction<PublishForecastPayload>
    ) => {},
    getNextPageOfForecasts: (state, action: PayloadAction<string>) => {

    }
  },
  extraReducers: (builder) => {
    builder.addCase(assetSlice.actions.selectAsset, (state, action) => {
      state.forecasts = [];
      delete state.page;
      delete state.isLastPage;
      delete state.selectedForecast
    })
  }
});
