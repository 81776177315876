import React, { FC } from "react";
import moment from "moment";

export type ChartDateLabelProps = {
  from: Date;
  to: Date;
};

const ChartDateLabel: FC<ChartDateLabelProps> = ({ from, to }) => {
  const now = new Date();
  const currentDate = moment(to).subtract(1, "second");
  const todayStr =
    moment(from).isBefore(now) && moment(to).isAfter(now) ? `Today - ` : "";

  return (
    <div
      style={{
        grid: "initial",
        gap: "0.5rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        alignItems: "baseline"
      }}
      data-testid={"chartDateLabel"}
    >
      <div
        style={{ fontWeight: "bold", fontSize: "1.125rem", lineHeight: "1.75rem" }} 
        data-testid={"chartDateDayOfWeek"}>
        {todayStr + currentDate.format("dddd")}
      </div>
      <div data-testid={"chartDateNumericalDate"}>
        {currentDate.format("DD/MM/YYYY")}
      </div>
    </div>
  );
};

export default ChartDateLabel;
