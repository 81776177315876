import React, { FC, useRef, useState, memo, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

import { D3BarChart, DataTime, TimeRange, SelectedEvent } from "./d3BarChart";
import ToolTip from "./ToolTip";

export type BarChartProps = {
  timeRange: TimeRange;
  minutesInterval: number;
  data: DataTime[];
  dataFrom?: DataTime[];
  current?: Date;
  axis?: boolean;
  maxValue?: number;
  hover?: boolean;
};

const defaultDataFrom: DataTime[] = [];

const BarChart: FC<BarChartProps> = ({
  timeRange,
  minutesInterval,
  dataFrom = defaultDataFrom,
  data,
  current = new Date(),
  axis = true,
  maxValue,
  hover = false,
}: BarChartProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<SelectedEvent>();
  const [mousePosition, setMousePostition] = useState<{ x: number; y: number }>(
    { x: 0, y: 0 }
  );
  const [chart, setChart] = useState<D3BarChart>();

  useEffect(() => {
    if (!divRef.current) return;
    const draw = () => {
      if (!divRef.current) return;
      const newChart = new D3BarChart(
        timeRange,
        minutesInterval,
        divRef.current,
        dataFrom,
        data,
        axis,
        maxValue,
        hover ? setSelected : undefined
      );
      setChart(newChart);
    };
    draw();

    const observer = new ResizeObserver(draw);
    observer.observe(divRef.current);
    return () => observer.disconnect();
  }, [
    divRef,
    data,
    dataFrom,
    axis,
    hover,
    setSelected,
    setChart,
    maxValue,
    minutesInterval,
    timeRange,
  ]);

  if (chart) {
    chart.currentTime = current;
    chart.setSelected(selected?.key);
  }

  const toolTipWidth = 150;

  const computeXPosition = () => {
    if (!selected || !divRef.current) return;
    if (
      selected.bar.end + 10 + toolTipWidth >
      divRef.current?.getBoundingClientRect().width
    ) {
      return selected.bar.start - 10 - toolTipWidth;
    } else {
      return selected.bar.end + 10;
    }
  };

  return (
    <div
      data-testid="barChart"
      style={{ position: "relative", height: "100%", width: "100%", userSelect: "none" }}
      onMouseOut={() => setSelected(undefined)}
      onMouseMove={(event) => {
        const currentTargetRect = divRef.current?.getBoundingClientRect();
        if (currentTargetRect) {
          const x = event.pageX - currentTargetRect.left;
          const y = event.pageY - currentTargetRect.top;
          setMousePostition({ x, y });
        }
      }}
    >
      <div style={{ height: "100%", width: "100%" }} ref={divRef}></div>
      {selected && (selected.dataFrom || selected.dataTo) && (
        <div
          style={{
            position: "absolute",
            top: mousePosition.y,
            left: computeXPosition(),
            width: toolTipWidth,
          }}
        >
          <ToolTip
            from={selected.dataFrom?.value}
            to={selected.dataTo?.value}
          />
        </div>
      )}
    </div>
  );
};

export default memo(BarChart);
