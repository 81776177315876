import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  getContext,
  takeLatest,
  takeEvery,
  all,
  select,
  delay,
  fork,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

import {
  forecastSlice,
  PublishedForecast,
  SelectForecastPayload,
  PublishForecastPayload,
  PublishedPrediction,
  ForecastPredictions,
  Forecast
} from "../forecastSlice";
import { IWidgetApiClient, RetrieveForecastListResult } from "../../ApiClient";
import { assetSlice } from "../assetSlice";
import {currentAssetSelector, currentPageSelector, isLastPageSelector, selectedForecastSelector, timeRangeSelector} from "../selectors";
import { REFRESH_ACTION } from "../actions";

const forecastSelectTimeRange = () => {
  // When we select a forecast, we only want predictions for 23:00 yesterday -> 23:00 today for now
  // Get forecasts from last 23h00 to next 23h00
  // TimeRange held in state is used for the timeRange to get forecasts, not predictions within a forecast
  const nextHour = moment().add(1, "hour");
  const from = moment(nextHour).startOf("day").subtract(1, "hour").toDate();
  const to = moment(from).add(1, "day").toDate();
  return { from, to };
};

export function* updateForecasts(assetId: string, forecasts: Forecast[], page: number, isLastPage: boolean) {
  const apiClient = (yield getContext("apiClient")) as IWidgetApiClient;
  // Only select data for 23:00 yesterday to 23:00 today
  const { from, to } = forecastSelectTimeRange();

  // Get predictions for the latest forecast
  forecasts.sort(
    (a, b) => b.predictionMoment.valueOf() - a.predictionMoment.valueOf()
  );

  const [latestForecast, publishedPredictions] = forecasts[0]
    ? ((yield all([
        call(
          apiClient.retrieveForecast.bind(apiClient),
          assetId,
          forecasts[0].id,
          moment(from).toISOString(),
          moment(to).toISOString()
        ),
        call(
          apiClient.retrievePublishedPosition.bind(apiClient),
          assetId,
          moment(from).toISOString(),
          moment(to).toISOString()
        ),
      ])) as [ForecastPredictions, PublishedPrediction[]])
    : [undefined, []];

  yield put(
    forecastSlice.actions.updateForecasts({
      forecasts,
      timeRange: { from: from.valueOf(), to: to.valueOf() },
      latestForecast,
      publishedPredictions,
      newPage: page,
      isLastPage
    })
  );
}

export function* getPageOfForecasts(assetId: string, page: number, updateStatePage: boolean) {
  const apiClient = (yield getContext("apiClient")) as IWidgetApiClient;

  // Only select data for 23:00 yesterday to 23:00 today
  const { from, to } = forecastSelectTimeRange();

  const { forecasts, isLastPage } : RetrieveForecastListResult = yield call(
    apiClient.retrieveForecastList.bind(apiClient), assetId,
    from,
    to,
    page
  );

  const newPage = updateStatePage ? page : yield(select(currentPageSelector)) ?? 0;
  const lastPage = updateStatePage ? isLastPage : yield(select(isLastPageSelector));

  yield updateForecasts(assetId, forecasts, newPage, lastPage);
}

export function* getNextPageOfForecasts({payload: assetId}: PayloadAction<string>) {
  const page = yield select(currentPageSelector);

  const newPage = page === undefined ? 0 : page + 1;

  yield getPageOfForecasts(assetId, newPage, true);
}

function* refreshForecasts() {
  const currentAsset = yield (select(currentAssetSelector));
  // we only need to refresh page 1 of forecasts, as others shouldn't change
  yield(getPageOfForecasts(currentAsset.id, 0, false));
}

function* loadingSelected() {
  yield delay(500);
  yield put(forecastSlice.actions.loadingSelectedForecast());
}

export function* selectForecast(action: PayloadAction<SelectForecastPayload>) {
  const apiClient = (yield getContext("apiClient")) as IWidgetApiClient;

  const loadingTask = yield fork(loadingSelected);
  const selectTimeRange = yield select(timeRangeSelector);

  const data = yield call(
    apiClient.retrieveForecast.bind(apiClient),
    action.payload.assetId,
    action.payload.forecastId,
    moment(selectTimeRange.from).toISOString(),
    moment(selectTimeRange.to).toISOString()
  );
  yield cancel(loadingTask);

  yield put(forecastSlice.actions.setSelectedForecast(data));
}

export function* updateAssets(clearSelected = true) {
  const apiClient = (yield getContext("apiClient")) as IWidgetApiClient;

  const assets = yield call(apiClient.retrieveAssets.bind(apiClient));
  yield put(assetSlice.actions.updateAssets({assets, clearSelected}));

  const selected = yield (select(currentAssetSelector))
  if (selected) {
    if (clearSelected) {
      yield getNextPageOfForecasts({ payload: selected.id, type: 'string'});
    }
    else {
      yield refreshForecasts();
    }
  }
  else {
    const firstAsset = assets[0];
    if (firstAsset) {
      yield getNextPageOfForecasts({ payload: firstAsset.id, type: 'string'});
    }
  }
}

export function* publishForecast(
  action: PayloadAction<PublishForecastPayload>
) {
  const { assetId, forecastId } = action.payload;
  const apiClient = (yield getContext("apiClient")) as IWidgetApiClient;

  try {
    yield call(apiClient.publishForecast.bind(apiClient), assetId, forecastId);
  } catch (e) {
    console.error(e); // TODO: put error message in state
    return;
  }

  const { from, to } = yield select(timeRangeSelector);

  const [newPublishedForecasts, publishedPredictions]: [
    PublishedForecast[],
    PublishedPrediction[]
  ] = yield all([
    call(
      apiClient.retrievePublishedForecastList.bind(apiClient),
      action.payload.assetId,
      moment(from).toDate(),
      moment(to).toDate(),
      0
    ),
    call(
      apiClient.retrievePublishedPosition.bind(apiClient),
      assetId,
      moment(from).toISOString(),
      moment(to).toISOString()
    ),
  ]);

  const publishedForecast = newPublishedForecasts.find(
    (pf) => pf.forecastId === action.payload.forecastId
  );

  if (publishedForecast) {
    yield put(
      forecastSlice.actions.setForecastPublished({
        ...publishedForecast,
        publishedPredictions,
      })
    );
  } else {
    console.warn(
      "Post success but forecast id was not found in subsequent published forecasts response"
    );
  }
}

export function* refresh() {
  const asset = yield(select(currentAssetSelector));
  const forecast = yield(select(selectedForecastSelector));
  
  // Update asset list. If we've an asset selected, this should automatically re-select it and refresh forecast list
  yield updateAssets(false);

  // If we've a forecast selected, refresh chart data
  if (forecast) {
    yield selectForecast({ payload: { assetId: asset.id , forecastId: forecast.id }, type: 'string'});
  }
}

export function* rootSagas() {
  yield takeEvery(assetSlice.actions.selectAsset, getNextPageOfForecasts);
  yield takeLatest(forecastSlice.actions.selectForecast, selectForecast);
  yield takeEvery(forecastSlice.actions.getNextPageOfForecasts, getNextPageOfForecasts);
  yield takeEvery(REFRESH_ACTION, refresh);
  yield takeEvery(forecastSlice.actions.publishForecast, publishForecast);
  yield takeEvery(assetSlice.actions.getAssetList, updateAssets);
}
