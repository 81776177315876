import {
  LoadedEvent,
  IconButton,
  IconButtonsEvent,
} from "./CustomWidgetEvents";
import { EventListenerRegistry } from "./ExternalEventListenerProvider";

const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/-/g, "");
};

const extractBaseUrl = (url: string) => {
  return url.substring(0, url.lastIndexOf("/")+1);
};

export abstract class CustomWidgetBase<T> extends HTMLElement {
  protected root: ShadowRoot;
  protected eventListenerRegistry: EventListenerRegistry;
  protected baseUrl: string;

  private observer: MutationObserver;
  private listenersMap: { [key: string]: EventListener[] } = {};

  constructor(private iconButtons: IconButton[] = []) {
    super();

    this.eventListenerRegistry = new EventListenerRegistry(this);
    this.root = this.attachShadow({ mode: "open" });
    this.observer = new MutationObserver((e) => this.update(e));
    this.observer.observe(this, { attributes: true });

    const url = document?.currentScript?.getAttribute("src");
    this.baseUrl = (url && extractBaseUrl(url)) || "./";
  }

  abstract mount(): void;
  abstract unmount(): void;

  connectedCallback() {
    this._mount();
  }

  disconnectedCallback() {
    this._unmount();
    this.observer.disconnect();
  }

  addEventListener(eventType: string, eventListener: EventListener) {
    this.listenersMap[eventType] = this.listenersMap[eventType] || [];
    this.listenersMap[eventType].push(eventListener);
    super.addEventListener(eventType, eventListener);
  }

  protected get props() {
    const props: any = {};
    for (let i = 0; i < this.attributes.length; i++) {
      const attr = this.attributes.item(i)!;
      props[camelize(attr.name)] = attr.value;
    }
    return props as T;
  }

  update(e: MutationRecord[]) {
    this._unmount();
    this._mount();
  }

  private cleanup() {
    Object.entries(this.listenersMap).forEach(([eventType, listeners]) =>
      listeners.forEach((listener) => {
        this.removeEventListener(eventType, listener);
      })
    );
    this.eventListenerRegistry.reset();
  }

  private _mount() {
    //wait dashboard is readiness before sending events
    this.addEventListener(LoadedEvent.EVENT_TYPE, () => {
      this.dispatchEvent(new IconButtonsEvent(this.iconButtons));
    });
    // LoadedEvent event MUST be dispatch when the component is ready
    this.dispatchEvent(new LoadedEvent());
    this.mount();
  }

  private _unmount() {
    this.unmount();
    this.cleanup();
  }
}
