import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";

export type Asset = {
    id: string;
    label: string;
};

export type AssetState = EntityState<Asset>  & {
    selectedAsset?: string,
    loadingAssets: boolean
};

export const assetAdapter = createEntityAdapter<Asset>(
    {
        selectId: asset => asset.id,
    }
)

export const getAssetState = (assetList: Asset[], selectedAsset?: string) => ({
    ids: assetList.map(asset => asset.id),
    entities: assetList.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {}),
    selectedAsset: selectedAsset,
    loadingAssets: false
})

export type UpdateAssetsPayload = {
    assets: Asset[],
    clearSelected: boolean
}

export const assetSlice = createSlice({
    name: 'assets',
    initialState: getAssetState([]),
    reducers: {
        updateAssets: (state, action: PayloadAction<UpdateAssetsPayload>) => {
            state.loadingAssets = false;
            assetAdapter.removeAll(state);
            assetAdapter.addMany(state, action.payload.assets);
            if (action.payload.clearSelected ||
                !action.payload.assets.find(a => a.id === state.selectedAsset)) {
                delete state.selectedAsset;
                if(action.payload.assets.length > 0) {
                    state.selectedAsset = action.payload.assets[0].id;
                }
            }
        },
        getAssetList: (state) => { state.loadingAssets = true; }, 
        selectAsset: (state, action: PayloadAction<string>) => {state.selectedAsset = action.payload},
        refresh: (state) => { }
    }
})
