import React, { FC } from 'react';
import { BoxArrowRight } from '@styled-icons/bootstrap/BoxArrowRight';

export type PublishButtonProps = {
    onClick: () => void
} 

const PublishButton: FC<PublishButtonProps> = ({ onClick }) => {

    return (
        <button 
            className="btn btn-publish" 
            data-testid="publishButton"
            onClick={onClick}
        >
            <div 
                style={{
                    paddingRight: "0.25rem",
                    width: "1rem",
                    height: "1rem",
                    float: "left",
                    textAlign: "center"
                }}
            >
                <BoxArrowRight />
            </div>
            <div 
                style={{
                    float: "left",
                    textAlign: "center"
                }}
            >
                Publish
            </div>
        </button>
    );
}

export default PublishButton;