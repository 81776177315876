import React, { FC } from "react";
import { ArrowDropUp } from "@styled-icons/material/ArrowDropUp";
import { ArrowDropDown } from "@styled-icons/material/ArrowDropDown";

export type ToolTipProps = {
  from?: number;
  to?: number;
};

const mwConvertion = (value: number) => {
  return Math.round((value / 1_000_000) * 100) / 100;
};

const ToolTip: FC<ToolTipProps> = ({ from, to }) => {
  return (
    <div 
      data-testid="tooltip"
      style={{
        width: "100%",
        borderRadius: "0.125rem",
        lineHeight: "1rem",
        fontSize: "0.75rem",
        backgroundColor: "rgba(17, 24, 39, 1)"
        // TODO: Replace tw "shadow" here
      }}
    >
      <table>
        <tbody>
          {to !== undefined && (
            <tr data-testid="forecast">
              <td>Forecast</td>
              <td>{mwConvertion(to)} MW</td>
            </tr>
          )}
          {from !== undefined && (
            <tr data-testid="previous">
              <td>Previous</td>
              <td>{mwConvertion(from)} MW</td>
            </tr>
          )}
          {to !== undefined && from !== undefined && to !== from && (
            <tr data-testid="variance">
              <td>Variance</td>
              <td>
                {mwConvertion(Math.abs(to - from))} MW
                {to - from > 0 ? (
                  <ArrowDropUp data-testid="arrowUp" size="32" color="#13796E" />
                ) : (
                  <ArrowDropDown data-testid="arrowDown" size="32" color="#B60F14" />
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ToolTip;
