import React, { useEffect } from "react";
import { IconButtonClickEvent, ThemeChangeEvent } from "./CustomWidgetEvents";

export class EventListenerRegistry {
  private iconButtons: {
    [key: string]: [(e: IconButtonClickEvent) => void];
  } = {};

  private lastThemeChangeEvent?: ThemeChangeEvent;

  constructor(private hostElement: HTMLElement) {
    this.setupIconButtonsListener();
    this.setupThemeChangeListener();
  }

  public reset() {
    this.iconButtons = {};
    this.setupIconButtonsListener();
    this.setupThemeChangeListener();
  }

  public registerIconButton(
    key: string,
    action: (e: IconButtonClickEvent) => void
  ) {
    this.iconButtons[key] = this.iconButtons[key] || [];
    this.iconButtons[key].push(action);
  }

  public registerThemeChange(eventListener: (e: ThemeChangeEvent) => void) {
    if (this.lastThemeChangeEvent) {
      eventListener(this.lastThemeChangeEvent);
    }
    this.hostElement.addEventListener(
      ThemeChangeEvent.EVENT_TYPE,
      eventListener as EventListener
    );
  }

  private setupIconButtonsListener() {
    this.hostElement.addEventListener(IconButtonClickEvent.EVENT_TYPE, ((
      e: IconButtonClickEvent
    ) => {
      this.iconButtons[e.detail]?.forEach((action) => action(e));
    }) as EventListener);
  }

  private setupThemeChangeListener() {
    this.hostElement.addEventListener(ThemeChangeEvent.EVENT_TYPE, ((
      e: ThemeChangeEvent
    ) => {
      this.lastThemeChangeEvent = e;
    }) as EventListener);
  }
}

const EventListenerRegistryContext = React.createContext<
  EventListenerRegistry | undefined
>(undefined);

const isDefined = (eventRegistry: EventListenerRegistry | undefined) => {
  if (!eventRegistry)
    throw new Error(
      "ExternalEventListenerProvider must be set be using useExternalEventListener"
    );
  return eventRegistry;
};

export const useIconButtonClick = (
  key: string,
  eventListener: (e: IconButtonClickEvent) => void
) =>
  isDefined(React.useContext(EventListenerRegistryContext)).registerIconButton(
    key,
    eventListener
  );

export const useThemeChange = (
  eventListener: (e: ThemeChangeEvent) => void
) => {
  const context = React.useContext(EventListenerRegistryContext);
  useEffect(() => isDefined(context).registerThemeChange(eventListener), [
    context,
    eventListener,
  ]);
};

export default EventListenerRegistryContext.Provider;
