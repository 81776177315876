import React, { FC, HTMLAttributes } from "react";

type ForecastListItemStylingProps = HTMLAttributes<HTMLLIElement>

const StyledForecastLi: FC<ForecastListItemStylingProps> = ({children, ...props} ) => {
  return (
    <li
      style={{
        height: "1.5rem",
        width: "100%",
        margin: "1px 0px",
        padding: "0.25rem 1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
      {...props}
    >
      {children}
    </li>
  )
}

export default StyledForecastLi
