import moment from "moment";
import assert from "assert";
const PUBLISHED_FORECAST = "TEST_PUBLISHED_FORECAST";

export const DEFAULT_MOCK_SERVER_PAGE_SIZE = 30;

const getPredictionMomentFromId = (forecastId: string) => {
  const [, , predictionMoment] = forecastId.match(/ID_([^_]+)_([^_]+)/) || [];
  return predictionMoment;
};

const getItem = <T>(key: string, defaultValue: T) => {
  const item = localStorage.getItem(key);
  if (item === null) return defaultValue;
  return JSON.parse(item) as T;
};

const setItem = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

function fakeRandom(seed = 1) {
  return () => {
    let x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
}

const hashCode = (value: string) => {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export type PublishedForecast = {
  id: string;
  resource: {
    forecastId: string;
    assetId: string;
    publishedDate: number;
    latest: boolean;
  };
};

export type Prediction = {
  resource: {
    targetDeliveryStart: string;
    targetDeliveryEnd: string;
    power: number;
    name: string;
  };
};

export type PublishedPrediction = {
  targetDeliveryStart: string;
  targetDeliveryEnd: string;
  forecast: {
    name: string;
    id: string;
    prediction: {
      moment: string;
      power: number;
      name: string;
      id: string;
    };
  };
  publishedForecast: {
    id: string;
    published: string;
  };
};

export type Forecast = {
  id: string;
  resource: {
    name: string;
    predictionMoment: string;
    startTargetPeriod: string;
    endTargetPeriod: string;
    targetPeriodLengths: number;
  };
};

const generatePeriods = (
  from: moment.Moment,
  to: moment.Moment,
  minutesInterval: number
) => {
  const result: string[] = [];
  const last = moment(to);
  let current = moment(from);
  while (!current.isAfter(last)) {
    result.push(current.toISOString());
    current.add(minutesInterval, "minute");
  }
  return result;
};

const roundedTimeInterval = (start: moment.Moment, interval: number) => {
  const remainder = start.startOf("minute").minute() % interval;
  return moment(start).subtract(remainder, "minutes");
};

export const genPredictions = (
  assetId: string,
  forecastId: string,
  end: string,
  interval = 30
) => {
  const start = getPredictionMomentFromId(forecastId);
  const roundedStart = roundedTimeInterval(moment(start), interval);
  const periods = generatePeriods(roundedStart, moment(end), interval);
  const predictions: Prediction[] = [];
  for (let i = 1; i < periods.length; i++) {
    predictions.push({
      resource: {
        name: "prediction_" + forecastId,
        targetDeliveryStart: periods[i - 1],
        targetDeliveryEnd: periods[i],
        power:
          fakeRandom(hashCode(assetId + forecastId + periods[i]))() * 500_000 +
          200_000,
      },
    });
  }
  return predictions;
};

export const genPagedForecasts = (
  assetId: string,
  start: string,
  end: string,
  page: number,
  pageSize = DEFAULT_MOCK_SERVER_PAGE_SIZE
) => {
  const periods = generatePeriods(
    roundedTimeInterval(moment(start), 30).subtract(2, "days"),
    moment(),
    30
  );

  const latestForecast = Math.max(0, periods.length - page * pageSize);
  const earliestForecast = Math.max(0, periods.length - (page + 1) * pageSize);
  const isLastPage = earliestForecast === 0;

  const forecasts: Forecast[] = periods
    .map((period) => period.toString())
    .map((periodString) => {
      const id = `ID_${assetId}_${periodString}`;
      return {
        id,
        resource: {
          name: `NAME_${periodString}`,
          predictionMoment: periodString,
          startTargetPeriod: start,
          endTargetPeriod: end,
          targetPeriodLengths: 30 * 60,
        },
      };
    });

  return {
    forecasts: forecasts.slice(earliestForecast, latestForecast),
    isLastPage,
  };
};

export const publishForecast = (assetId: string, forecastId: string) => {
  const publishedForecasts: PublishedForecast[] = getItem(
    PUBLISHED_FORECAST,
    []
  );
  publishedForecasts.forEach((f) => (f.resource.latest = false));
  const forecast = publishedForecasts.find(
    (pf) => pf.resource.forecastId === forecastId
  );
  if (!forecast) {
    publishedForecasts.push({
      id: `PUBLISHED_${forecastId}`,
      resource: {
        assetId,
        forecastId,
        publishedDate: new Date().getTime(),
        latest: true,
      },
    });
  } else {
    forecast.resource.publishedDate = new Date().getTime();
    forecast.resource.latest = true;
  }
  setItem(PUBLISHED_FORECAST, publishedForecasts);
};

export const getPublishedForecast = (assetId: string) => {
  return getItem(PUBLISHED_FORECAST, [] as PublishedForecast[])
    .filter(
      (publishedForecast) => publishedForecast.resource.assetId === assetId
    )
    .sort((p1, p2) => p1.resource.publishedDate - p2.resource.publishedDate);
};

export const getPositionForecast = (assetId: string, end: string) => {
  const publishedForecast = getPublishedForecast(assetId).sort(
    (p1, p2) => p1.resource.publishedDate - p2.resource.publishedDate
  );

  const positionForecast = publishedForecast
    .flatMap((pf) =>
      genPredictions(pf.resource.assetId, pf.resource.forecastId, end).map(
        (prediction) => ({
          targetDeliveryStart: prediction.resource.targetDeliveryStart,
          targetDeliveryEnd: prediction.resource.targetDeliveryEnd,
          forecast: {
            id: pf.resource.forecastId,
            name: "ORIGAMI_MOCK",
            prediction: {
              moment: getPredictionMomentFromId(pf.resource.forecastId),
              name: prediction.resource.name,
              power: prediction.resource.power,
              id: "NONE",
            },
          },
          publishedForecast: {
            id: pf.id,
            published: moment(pf.resource.publishedDate).toISOString(),
          },
        })
      )
    )
    .reduce((acc, prediction) => {
      acc[prediction.targetDeliveryStart] = prediction;
      return acc;
    }, {} as { [key: string]: PublishedPrediction });

  return Object.values(positionForecast);
};
