export type IconButton = {
  key: string;
  title: string;
  iconName: string; // one of https://material.io/resources/icons/?style=baseline
};

export type Theme = {
  // TBD
  palette: {
    type: "light" | "dark";
    background: {
      paper: string;
      default: string;
    };
    text: {
      primary: string;
      secondary: string;
      disabled: string;
      hint: string;
    };
  };
};

export class LoadedEvent extends CustomEvent<void> {
  public static readonly EVENT_TYPE = "loaded";
  constructor() {
    super(LoadedEvent.EVENT_TYPE, { bubbles: true, composed: true });
  }
}

export class IconButtonsEvent extends CustomEvent<IconButton[]> {
  public static readonly EVENT_TYPE = "iconButtons";
  constructor(iconButtons: IconButton[]) {
    super(IconButtonsEvent.EVENT_TYPE, {
      bubbles: true,
      composed: true,
      detail: iconButtons,
    });
  }
}

export class IconButtonClickEvent extends CustomEvent<string> {
  public static readonly EVENT_TYPE = "iconButtonClick";
  constructor(key: string) {
    super(IconButtonClickEvent.EVENT_TYPE, {
      bubbles: true,
      composed: true,
      detail: key,
    });
  }
}

export class ThemeChangeEvent extends CustomEvent<Theme> {
  public static readonly EVENT_TYPE = "themeChange";
  constructor(theme: Theme) {
    super(ThemeChangeEvent.EVENT_TYPE, {
      bubbles: true,
      composed: true,
      detail: theme,
    });
  }
}
