import React, { FC } from "react";
import { Asset, assetSlice } from "../../redux/assetSlice";
import { useDispatch, useSelector } from "react-redux";
import { assetListSelector, currentAssetSelector } from "../../redux/selectors";

const AssetList: FC = () => {
  const assets = useSelector(assetListSelector);
  const selectedAsset = useSelector(currentAssetSelector);

  const dispatch = useDispatch();

  const classIsAssetSelected = (currentAsset: Asset) =>
    currentAsset.id === selectedAsset?.id ? "selected" : "";

  const assetList = assets.map((asset) => (
    <li
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "1px 0px",
        padding: "0rem 1rem",
        justifyContent: "center",
        height: "4rem"
      }}
      className={`paper ${classIsAssetSelected(asset)}`}
      key={asset.id}
      onClick={() => dispatch(assetSlice.actions.selectAsset(asset.id))}
      data-testid="assetListItem"
    >
      <div>{asset.label}</div>
    </li>
  ));

  return (
    <ul
      style={{ margin: 0, padding: 0, userSelect: "none" }}
      data-testid="assetList"
    >
      {assetList}
    </ul>
  );
};

export default AssetList;
