import React, { FC, useCallback } from "react";
import { forecastSlice } from "../../redux/forecastSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ChartDateLabel from "./ChartDateLabel";
import BarChart from "./BarChart";
import {
  forecastDataChartSelector,
  selectedForecastMomentSelector,
} from "../../redux/selectors";
import PublishButton from "./PublishButton";
import {
  selectedForecastSelector,
  currentAssetSelector,
} from "../../redux/selectors";
import ChartLegend from "./ChartLegend";

export type ChartContainerProps = {};

const ChartContainer: FC<ChartContainerProps> = (
  props: ChartContainerProps
) => {
  const {
    timeRange,
    minutesInterval,
    publishedDataChart,
    selectedDataChart,
  } = useSelector(forecastDataChartSelector);

  const dispatch = useDispatch();
  const selectedForecast = useSelector(selectedForecastSelector);
  const selectedAsset = useSelector(currentAssetSelector);
  const selectedForecastMoment = useSelector(selectedForecastMomentSelector);
  const handlePublishClick = useCallback(() => {
    if (selectedAsset && selectedForecast) {
      dispatch(
        forecastSlice.actions.publishForecast({
          assetId: selectedAsset.id,
          forecastId: selectedForecast.id,
        })
      );
    } else {
      console.warn(
        "Publish button clicked with either no selected forecast or asset"
      );
    }
  }, [selectedAsset, selectedForecast, dispatch]);

  return (
    <div data-testid="chartWrapper"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      {timeRange && minutesInterval > 0 ? (
        <>
          <div data-testid="chartHeader" style={{ width: "100%" }}>
            <div data-testid="chartLabel" style={{ padding: "1.5rem", float: "left" }}>
              <ChartDateLabel {...timeRange} />
            </div>
            {selectedForecast ? (
              <div
                data-testid="publishButtonContainer"
                style={{ float: "right", padding: "1.5rem" }}
              >
                <PublishButton onClick={handlePublishClick} />
              </div>
            ) : null}
          </div>
          <div data-testid="chartContainer" style={{ width: "100%", flexGrow: 1 }}>
            <BarChart
              timeRange={timeRange}
              minutesInterval={minutesInterval}
              dataFrom={publishedDataChart}
              data={selectedDataChart}
              hover={true}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: "1 1 0%" }}>
              <ChartLegend
                forecastPredictionMoment={moment(selectedForecastMoment)}
              />
            </div>
          </div>
        </>
      ) : undefined}
    </div>
  );
};

export default ChartContainer;
