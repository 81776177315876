import { createServer, Response } from "miragejs";
import moment from "moment";
import assetsData from "./assetsData";
import {
  genPagedForecasts,
  genPredictions,
  getPositionForecast,
  getPublishedForecast,
  publishForecast,
} from "./data";

export default () =>
  createServer({
    routes() {
      const apiUrl = "https://api.fe.test.oerplus.io";
      this.timing = 0;

      this.get(apiUrl + "/auth/refresh", () => {
        return new Response(204);
      });

      this.get(
        apiUrl + "/v1/assets/:assetId/forecasts/:forecastId/predictions",
        (schema, req) => {
          const { assetId, forecastId } = req.params;
          const { targetDeliveryEnd } = req.queryParams;

          return {
            content: genPredictions(assetId, forecastId, targetDeliveryEnd, 30),
          };
        }
      );

      this.get(apiUrl + "/v1/assets/:assetId/forecasts", (schema, req) => {
        const { assetId } = req.params;

        const { start, end, page } = req.queryParams;
        const {forecasts, isLastPage}= genPagedForecasts(assetId, start, end, parseInt(page))
        return { content: forecasts, last: isLastPage };
      });

      this.get(apiUrl + "/v1/assets", (schema, req) => {
        return { content: assetsData };
      });

      this.post(
        apiUrl + "/v1/assets/:assetId/published-forecasts",
        (schema, req) => {
          const { assetId } = req.params;
          const { forecastId } = JSON.parse(req.requestBody);
          publishForecast(assetId, forecastId);
          return new Response(201);
        }
      );

      this.get(
        apiUrl + "/v1/assets/:assetId/published-forecasts",
        (schema, req) => {
          const { assetId } = req.params;

          const publishedForecasts = getPublishedForecast(assetId).map(
            (pf) => ({
              id: pf.id,
              resource: {
                forecastId: pf.resource.forecastId,
              },
              _metadata: {
                publishedDate: moment(pf.resource.publishedDate),
                latest: pf.resource.latest,
              },
            })
          );
          if (publishedForecasts.length > 0)
            publishedForecasts[
              publishedForecasts.length - 1
            ]._metadata.latest = true;
          return { content: publishedForecasts };
        }
      );

      this.get(
        apiUrl + "/v1/assets/:assetId/forecast-position",
        (schema, req) => {
          const { assetId } = req.params;
          const { targetDeliveryRangeEnd } = req.queryParams;

          return getPositionForecast(assetId, targetDeliveryRangeEnd);
        }
      );
      this.passthrough('config.json');
    },
  });
