import React, { FC, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  currentAssetSelector,
  forecastListSelector,
  isLastPageSelector,
  selectedForecastSelector,
} from "../../redux/selectors";
import {
  Forecast,
  forecastSlice,
  PublishedForecast,
} from "../../redux/forecastSlice";
import StyledForecastLi from "./StyledForecastLi";

export type ForecastListProps = {
  testingRef?: HTMLDivElement;
  now?: Date; // used for testing
  onForecastSelect: (forecast: string) => void;
};

const toLabel = (forecastMomentMoment: moment.Moment, now: Date) => {
  if (forecastMomentMoment.isSame(now, "day")) {
    return forecastMomentMoment.format("HH:mm");
  }
  return forecastMomentMoment.format("HH:mm ddd DD/MM/YYYY");
};

const ForecastList: FC<ForecastListProps> = ({
  onForecastSelect,
  now = new Date(),
}) => {
  const isLastPage = useSelector(isLastPageSelector);
  const forecasts = useSelector(forecastListSelector);
  const selectedForecast = useSelector(selectedForecastSelector);
  const sortedForecasts = [...forecasts].sort(
    (a, b) => b.predictionMoment - a.predictionMoment
  );
  const dispatch = useDispatch();
  const selectedAsset = useSelector(currentAssetSelector);

  const publishedForecasts: PublishedForecast[] = sortedForecasts
    .filter((f) => f.published !== undefined)
    .map((f) => ({
      forecastId: f.id,
      publishedDate: f.published!,
      isLatest: f.isLatestPublished!,
    }))
    .sort((a, b) => b.publishedDate - a.publishedDate);

  const latestPublishedId = publishedForecasts.find(
    (pf) => pf.isLatest === true
  )?.forecastId;
  const latestPublishedForecastPredictionMoment = forecasts.find(
    (f) => f.id === latestPublishedId
  )?.predictionMoment;

  const classIsForecastSelected = (forecastId: string) =>
    forecastId === selectedForecast?.id ? "selected" : "";

  const classIsAfterLatestPublish = (forecast: Forecast) => {
    if (latestPublishedForecastPredictionMoment) {
      return forecast.predictionMoment <
        latestPublishedForecastPredictionMoment &&
        forecast.id !== selectedForecast?.id
        ? "superseded"
        : "";
    }
    return "";
  };

  const PublishedLabel = (isLatest: boolean) => {
    const className = isLatest ? "tag-highlight" : "tag-italic";
    return (
      <span
        data-testid="publishedTag"
        style={{
          padding: "0rem 0.25rem",
          marginLeft: "0.5rem"
        }}
        className={`listTag ${className}`}
      >
        {isLatest ? "Published" : "Prev published"}
      </span>
    );
  };

  const isPublished = (forecast: Forecast) => {
    return (
      publishedForecasts.find((pf) => pf.forecastId === forecast.id) !==
      undefined
    );
  };

  const ListItem = (forecast: Forecast, label: string, isLatest: boolean) => {
    return (
      <StyledForecastLi
        data-testid="forecastListItem"
        className={`paper ${classIsForecastSelected(forecast.id)}`}
        key={forecast.id}
        onClick={() => onForecastSelect(forecast.id)}
      >
        <p
          data-testid="itemLabel"
          className={classIsAfterLatestPublish(forecast)}
        >
          <span data-testid="labelText">
            {`${label}${isLatest ? " - latest" : ""}`}
          </span>
          {isPublished(forecast)
            ? PublishedLabel(
                publishedForecasts.find((pf) => pf.forecastId === forecast.id)!
                  .isLatest
              )
            : null}
        </p>
      </StyledForecastLi>
    );
  };

  const forecastList = sortedForecasts.map((forecast, i) => {
    let forecastMoment = moment(forecast.predictionMoment);
    const label = toLabel(forecastMoment, now);

    return ListItem(forecast, label, i === 0);
  });

  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollableContainerRef.current === null) return;
    const element = scrollableContainerRef.current;
    const loadMore = () => {
      if (
        !isLastPage &&
        element.scrollHeight - element.scrollTop <= element.clientHeight * 1.05
      ) {
        dispatch(
          forecastSlice.actions.getNextPageOfForecasts(selectedAsset!.id)
        );
      }
    };
    element.addEventListener("scroll", loadMore);

    return () => element.removeEventListener("scroll", loadMore);
  }, [scrollableContainerRef, dispatch, isLastPage, selectedAsset]);

  return (
    <div
      ref={scrollableContainerRef}
      style={{
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
      }}
      data-testid={"scrollableContainer"}
    >
      <h5 data-testid="forecastListTitle" style={{ paddingLeft: "0.25rem", fontSize: "0.75rem", lineHeight: "1rem" }}>
        Forecast overview:
      </h5>
      <ul data-testid="forecastListItems" style={{ margin: 0, padding: 0 }}>
        {forecastList}
        {isLastPage ? (
          <StyledForecastLi data-testid={"forecastEndMessage"}>
            No more forecasts available
          </StyledForecastLi>
        ) : (
          <StyledForecastLi data-testid={"forecastListLoader"}>Loading...</StyledForecastLi>
        )}
      </ul>
    </div>
  );
};

export default ForecastList;
