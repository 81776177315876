import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AssetList from "./components/AssetList";
import ForecastList from "./components/ForecastList";
import ChartContainer from "./components/ChartContainer";

import {
  loadingAssetSelector,
  currentAssetSelector,
} from "./redux/selectors";
import { forecastSlice } from "./redux/forecastSlice";
import { REFRESH_ACTION } from "./redux/actions";

export type AppProps = {
  widgetId: string;
};

const App: FC<AppProps> = ({ widgetId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({type: REFRESH_ACTION});
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch])

  const selectedAsset = useSelector(currentAssetSelector);

  const assetsLoading = useSelector(loadingAssetSelector);
  if (assetsLoading) {
    return <div data-testid="loading">Loading...</div>;
  }
  return (
    <>
      <div 
        className="app"
        data-testid={widgetId}
        style={{
          width: "100%",
          height: "100%",
          display: "flex"
        }}
      >
        <div
          data-testid="assetListContainer"
          style={{
            height: "100%",
            width: "16.5%", // 1/6
            overflowY: "auto",
            overflowX: "hidden"
          }}
        >
          <AssetList />
        </div>
        <div
          data-testid="forecastListContainer"
          style={{
            height: "100%",
            width: "16.5%", // 1/6
            overflowY: "auto",
            overflowX: "hidden"
          }}
        >
          <ForecastList
            onForecastSelect={(forecastId: string) => {
              dispatch(
                forecastSlice.actions.selectForecast({
                  assetId: selectedAsset?.id!,
                  forecastId,
                })
              );
            }}
          />
        </div>
        <div style={{ height: "100%", flexGrow: 1}}>
          <ChartContainer />
        </div>
      </div>
    </>
  );
};

export default App;
