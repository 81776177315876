import React, { FC, useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import App, { AppProps } from "./App";
import { IWidgetApiClient } from "./ApiClient";
import GlobalStyle from "./GlobalStyle";
import { Theme } from "./CustomWidgetEvents";
import { useThemeChange } from "./ExternalEventListenerProvider";
import { setupStore } from "./redux/store";

type AppWrapperProps = AppProps & { apiClientPromise: Promise<IWidgetApiClient> };

const AppWrapper: FC<AppWrapperProps> = ({ apiClientPromise, ...props }) => {
  const [apiClient, setApiClient] = useState<IWidgetApiClient | undefined>(undefined);
  const [store, setStore] = useState<Store | undefined>(undefined);

  useEffect(() => {
    let isCanceled = false;

    apiClientPromise.then((newApiClient) => {
      if (!isCanceled) setApiClient(newApiClient);
    });
    return () => {
      isCanceled = true;
    };
  }, [setApiClient, apiClientPromise]);

  const [theme, setTheme] = useState<Theme | undefined>(undefined);

  useThemeChange((t) => {
    setTheme(t.detail);
  });

  useEffect(() => {
    if (!apiClient) return;

    const store = setupStore(apiClient);
    setStore(store);
  }, [apiClient, setStore]);

  if (!apiClient || !store) return <div>Loading...</div>;

  return (
    <Provider store={store}>
      {theme && <GlobalStyle theme={theme} />}
      <App {...props} />
    </Provider>
  );
};

export default AppWrapper;
