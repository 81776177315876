import { createGlobalStyle } from "styled-components";

import { Theme } from "./CustomWidgetEvents";

type GlobalStyleProps = {
  theme: Theme;
};

const GlobalStyle = createGlobalStyle`
:host > * {
    background: ${({ theme }: GlobalStyleProps) =>
      // theme.palette.background.default
      "#1A1F26"};
 }
}
 .paper {
  background: ${({ theme }: GlobalStyleProps) =>
    // theme.palette.paper.default
    "#232A2F"};
 }
 
  .paper:not(.selected):hover {
     background: ${({ theme }: GlobalStyleProps) =>
       // theme.palette.paper.hover
       "#2E3338"};
 }

 .paper.selected {
  background: ${({ theme }: GlobalStyleProps) =>
    // theme.palette.paper.selected
    "#606368"};
 }

 .paper .tag-italic {
  font-style: italic;
 }

 .paper .tag-highlight {
  border-radius: 0.125rem;
  background: ${({ theme }: GlobalStyleProps) => 
    // theme.palette.paper.tag-highlight
    "#7DD1F0"
  };
  color: ${({ theme }: GlobalStyleProps) => 
    "#1F333B"
  };
 }

 .paper p.superseded {
   color: ${({ theme }: GlobalStyleProps) =>
   // theme.palette.paper.superseded
   "#87898D"
  };
 }

 .popup {
  background: ${({ theme }: GlobalStyleProps) =>
    // theme.palette.paper.secondary-select
    "#373A41"};
 }

 .btn {
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  border: none;
  }
  .btn-publish {
    background-color:  ${({ theme }: GlobalStyleProps) => 
        // theme.palette.btn-warn
      "#FE9801"};
    }
 }
  .btn-publish:hover {
    background-color:  ${({ theme }: GlobalStyleProps) => 
      // theme.palette.btn-warn
      "#FE9801"};
 }
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #4C5055;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #4C5055;
}

::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #7E8184;
}

svg text {
  user-select: none
}

`;

export default GlobalStyle;
