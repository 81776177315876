import React, { FC } from "react";
import moment, {Moment} from "moment";
import {Dash} from "@styled-icons/bootstrap/Dash"
import {CircleFill} from "@styled-icons/bootstrap/CircleFill";

export type ChartLegendProps = {
    forecastPredictionMoment?: Moment,
    now? : Moment //used for testing
}

const toLabel = (forecastMomentMoment: Moment, now: Moment) => {
    if (forecastMomentMoment.isSame(now, "day")) {
        return forecastMomentMoment.format("HH:mm");
    }
    return forecastMomentMoment.format("DD/MM/YYYY HH:mm");
};

//TODO when we branch out into bar charts we might want to make this more generic -- e.g. passing in icons and labels as props
const ChartLegend: FC<ChartLegendProps> = ({forecastPredictionMoment, now =  moment()}) => {
    const forecastTime = forecastPredictionMoment === undefined ? '' : toLabel(forecastPredictionMoment, now)

    //I think we want `justify-evenly` here but when I try using it, tailwindcss comes up with a "not found" issue.
    return (
        <div 
            data-testid={'chartLegend'}
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                flexWrap: "wrap"
            }}
        >
            <div><Dash size="16"/> Forecast {forecastTime}</div>
            <div><CircleFill size="8" color="#0D4B49"/> Published Position</div>
        </div>
    )
}

export default ChartLegend